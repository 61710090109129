@font-face {
    font-weight: 500;
    font-family: 'SF Pro Display';
    font-style: normal;
    src: local('SF Pro Display Medium'), local('./SFProDisplay-Medium'),
        url('./SFProDisplay-Medium.woff2') format('woff2'),
        url('./SFProDisplay-Medium.woff') format('woff');
}

/* @font-face { */

/*    font-weight: 900; */

/*    font-family: 'SF Pro Display'; */

/*    font-style: normal; */

/*    src: local('SF Pro Display Heavy'), local('./SFProDisplay-Heavy'), */

/*        url('./SFProDisplay-Heavy.woff2') format('woff2'), */

/*        url('./SFProDisplay-Heavy.woff') format('woff'); */

/* } */

@font-face {
    font-weight: 100;
    font-family: 'SF Pro Display';
    font-style: normal;
    src: local('SF Pro Display Thin'), local('./SFProDisplay-Thin'),
        url('./SFProDisplay-Thin.woff2') format('woff2'),
        url('./SFProDisplay-Thin.woff') format('woff');
}

/* @font-face { */

/*    font-weight: 200; */

/*    font-family: 'SF Pro Display'; */

/*    font-style: normal; */

/*    src: local('SF Pro Display Light'), local('./SFProDisplay-Light'), */

/*        url('./SFProDisplay-Light.woff2') format('woff2'), */

/*        url('./SFProDisplay-Light.woff') format('woff'); */

/* } */

/* @font-face { */

/*    font-weight: bold; */

/*    font-family: 'SF Pro Display'; */

/*    font-style: normal; */

/*    src: local('SF Pro Display Bold'), local('./SFProDisplay-Bold'), */

/*        url('./SFProDisplay-Bold.woff2') format('woff2'), */

/*        url('./SFProDisplay-Bold.woff') format('woff'); */

/* } */

/* @font-face { */

/*    font-weight: 900; */

/*    font-family: 'SF Pro Display'; */

/*    font-style: normal; */

/*    src: local('SF Pro Display Black'), local('./SFProDisplay-Black'), */

/*        url('./SFProDisplay-Black.woff2') format('woff2'), */

/*        url('./SFProDisplay-Black.woff') format('woff'); */

/* } */

/* @font-face { */

/*    font-weight: 200; */

/*    font-family: 'SF Pro Display'; */

/*    font-style: normal; */

/*    src: local('SF Pro Display Ultralight'), local('./SFProDisplay-Ultralight'), */

/*        url('./SFProDisplay-Ultralight.woff2') format('woff2'), */

/*        url('./SFProDisplay-Ultralight.woff') format('woff'); */

/* } */

@font-face {
    font-weight: 600;
    font-family: 'SF Pro Display';
    font-style: normal;
    src: local('SF Pro Display Semibold'), local('./SFProDisplay-Semibold'),
        url('./SFProDisplay-Semibold.woff2') format('woff2'),
        url('./SFProDisplay-Semibold.woff') format('woff');
}

@font-face {
    font-weight: normal;
    font-family: 'SF Pro Display';
    font-style: normal;
    src: local('SF Pro Display Regular'), local('./SFProDisplay-Regular'),
        url('./SFProDisplay-Regular.woff2') format('woff2'),
        url('./SFProDisplay-Regular.woff') format('woff');
}
